import $ from "jquery";

export default (tabs) => {
    const serverErrors = $('.server-error');

    const validateTabs = (invalidInputs) => {
        for (let i = invalidInputs.length - 1; i >= 0; i--) {
            const parent = tabs
                    .filter('[tab-section="' + $(invalidInputs[i])
                    .parents("[tab-section]")
                    .attr('tab-section') +'"]')
                    .addClass('error');

            if (i === 0) parent.trigger('click');
        }
    };

    const validateTabsOnSubmit = () => {
        $(':submit').click(function(e) {
            const inputs = $(e.target).parents('form').find('input');
            tabs.removeClass('error');

            let invalidInputs = [];
            for (let i = 0; i < inputs.length; i++) {
                if (!inputs[i].checkValidity()) invalidInputs.push(inputs[i]);
            }

            validateTabs($.merge(invalidInputs, serverErrors));
        })
    };

    validateTabs(serverErrors);
    validateTabsOnSubmit();
}
