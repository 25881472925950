import $ from 'jquery';
import './pixabay_autocomplete'

/**
 * Modul input_autocomplete
 * Turns an input field into an autocomplete field.
 * https://goodies.pixabay.com/javascript/auto-complete/demo.html
 */
export default (input, autocompleteUrl, onSelectCallback, spinnerCallback, renderItemCallback) => {
    let xhr;
    const options = {
        minChars: 2,
        source: (term, suggest) => {
            spinnerCallback(input, true);

            /**
             * abort unfinished AJAX requests before starting new ones
             */
            try {
                xhr.abort();
            } catch(e) {}

            xhr = $.getJSON(
                autocompleteUrl,
                { q: term },
                (data) => {
                    spinnerCallback(input, false);
                    suggest($.map(data.results, (dataItem) => dataItem.name));
                }
            );
        }
    };

    if (renderItemCallback !== null && renderItemCallback !== undefined) {
        options.renderItem = renderItemCallback;
    }

    if (onSelectCallback !== null && onSelectCallback !== undefined) {
        /**
         * Wrapper around the SelectCallback function to prevent a form submit on Enter.
         */
        const KEY_CODE_ENTER = 13;
        options.onSelect = (e, value) => {
            if (e.originalEvent.keyCode === KEY_CODE_ENTER) {
                e.preventDefault();
            }
            onSelectCallback(e, value);
            $(input).trigger('change.alertUnsavedChanges');
        };
    }

    $(input).autoComplete(options);
}
