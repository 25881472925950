export default (confirmCb, cancelCb, options = {}) => {
    const defaults = {
        title: '',
        content: '',
        content_class: 'panel info mb',
        confirm_button: 'Ok',
        confirm_button_class: 'button right',
        cancel_button: 'Abbrechen',
        cancel_button_class: 'button grey',
        hideable: true,
        css: {},
    };
    const settings = Object.assign(defaults, options);
    const container = document.createElement('div');

    const title = document.createElement('h3');
    title.textContent = settings.title;

    const content = document.createElement('p');
    content.innerHTML = settings.content;
    content.classList.add(...settings.content_class.split(" "));

    const confirmButton = document.createElement('button');
    confirmButton.textContent = settings.confirm_button;
    confirmButton.classList.add(...settings.confirm_button_class.split(" "));
    confirmButton.type = 'button';

    const cancelButton = document.createElement('button');
    cancelButton.textContent = settings.cancel_button;
    cancelButton.classList.add(...settings.cancel_button_class.split(" "));
    cancelButton.type = 'button';

    container.appendChild(title);
    container.append(content);
    container.append(confirmButton);
    container.append(cancelButton);

    const modal = new psiac.Modal(container,{hideable: settings.hideable, css: settings.css});

    confirmButton.addEventListener('click', () => {
        confirmCb();
        modal.hide();
    });

    cancelButton.addEventListener('click', () => {
        cancelCb();
        modal.hide();
    });

    modal.reveal();
};
