import $ from 'jquery';

/**
 * Module input_spinner
 * Adds a Clear All button and a spinner to an input field.
 * With optional callback.
 */
export default function(input, cb) {
    var cls = 'clear-input';
    var $input = $(input);

    var clearInput = $('<div></div>')
        .addClass(cls)
        .click(function() {
            $input.val('');

            if (cb instanceof Function) {
                cb();
            }
        });

    if (!$input.prev().hasClass(cls)) {
        $input
            .before(clearInput)
            .on('toggleSpinner', function(event, state) {
                if (state) {
                    $input.prev().addClass('spinner');
                } else {
                    $input.prev().removeClass('spinner');
                }
            })
    }
};
